import { useEffect, useRef, useState } from "react";
import { validateTokenCreds } from "@/redux/actions/authActions";
import { cloneObject } from "@/utils/globalFunc";
import useUser from "./useUser";
import { useSharedResource } from "@/providers/SharedResource";

export const UserAuthenticationStatus = {
    AUTHENTICATED: 'AUTHENTICATED',
    GUEST: 'GUEST',
    PENDING: 'PENDING'
};

export default function useAuth({ onSignIn, onSignOut }) {
    const resolver = useSharedResource();

    const [userAuthenticationStatus, setUserAuthenticationStatus] = useState(() => {
        if ((typeof window !== 'undefined') && !validateTokenCreds()) {
            return UserAuthenticationStatus.GUEST;
        }

        return UserAuthenticationStatus.PENDING;
    });
    const [authUserActions] = useState(() => ({
        onSignIn: user => {
            setUserAuthenticationStatus(UserAuthenticationStatus.AUTHENTICATED);
            onSignIn?.(user);
        },
        onSignOut: () => {
            setUserAuthenticationStatus(UserAuthenticationStatus.GUEST);
            onSignOut?.();
        }
    }));
    const userRef = useRef();
    const { user } = useUser();

    useEffect(() => {
        resolver
            ?.finally(() => {
                const { onSignIn, onSignOut } = authUserActions;

                if (user && !userRef.current) {
                    onSignIn(cloneObject(user));
                }

                if (!user && userRef.current) {
                    onSignOut();
                }

                userRef.current = user;
            });
    }, [resolver, user, authUserActions]);

    return {
        userAuthenticationStatus,
        user
    };
}
