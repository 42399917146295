import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { config } from '@/src/utils/config';
import styles from './UserDropDown.module.scss';
import Link from 'next/link';
import { constants } from '@/src/utils/constants';
import clsx from "clsx";


export const GeneralSwitcherRoutes = ({onLogout}) => {
    
    const router = useRouter();
    const {dashboardSwitcher} = useSelector(state=>state.dashboardRoutesReducer)
    const currentURL = `${config.current_url}`;
    const [disableLogout, setDisableLogout] = useState(false);
    const propertyCount = useSelector(state=>state.dashboardProfileReducer.profile?.payload?.data?.properties_count);

    const beforeTriggerLogout = useCallback((e) => {
        setDisableLogout(true);
        onLogout(e);
    },[])
    
    if(dashboardSwitcher === 'guest') {
        return (
            <ul className={styles.menu}>
                <li className={styles.mitem}>
                    {/* <Link href={currentURL + constants.dashboard_route}> */}
                    <Link href={constants.dashboard_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><MyAccountSVG /></span>
                            <span className={styles.mlink__text}>My account</span>
                        </a>
                    </Link>
                </li>
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.trips_upcoming_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><MyBookingsSVG /></span>
                            <span className={styles.mlink__text}>My bookings</span>
                        </a>
                    </Link>
                </li>
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.wishlist_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><ListsSVG /></span>
                            <span className={styles.mlink__text}>Lists</span>
                        </a>
                    </Link>
                </li>
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.inbox_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><InboxSVG /></span>
                            <span className={styles.mlink__text}>Inbox</span>
                        </a>
                    </Link>
            </li>
            {!router?.asPath?.includes('list_space') &&
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.list_space_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><LypSVG /></span>
                            <span className={styles.mlink__text}>List your property</span>
                        </a>
                    </Link>
                </li>}
            <li className={styles.mitem}>
                <a
                    className={clsx(styles.mlink, `${disableLogout ? styles.disabled : ''}`)}
                    onClick={beforeTriggerLogout}
                >
                    <span className={styles.mlink__svg}><LogoutSVG /></span>
                    <span className={styles.mlink__text}>Sign out</span>
                </a>
            </li>
        </ul>
        )
    } else {
        return (
            <ul className={styles.menu}>
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.dashboard_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><MyAccountSVG /></span>
                            <span className={styles.mlink__text}>My account</span>
                        </a>
                    </Link>
                </li>
                
                
                <li className={styles.mitem}>
                    <Link href={currentURL + constants.booking_request_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><BookingRequest /></span>
                            <span className={styles.mlink__text}>Booking Requests</span>
                        </a>
                    </Link>
                </li>

                <li className={styles.mitem}>
                    <Link href={currentURL + constants.earnings_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><Earnings /></span>
                            <span className={styles.mlink__text}>Earnings</span>
                        </a>
                    </Link>
                </li>

                <li className={styles.mitem}>
                    <Link href={currentURL + constants.host_inbox_route}>
                        <a className={styles.mlink}>
                            <span className={styles.mlink__svg}><InboxSVG /></span>
                            <span className={styles.mlink__text}>Inbox</span>
                        </a>
                    </Link>
                </li>

                <li className={styles.mitem}>
                        <Link href={Boolean(propertyCount) ? currentURL + constants.manage_listings_route : currentURL + constants.list_space_route}>
                            <a className={styles.mlink}>
                                <span className={styles.mlink__svg}><LypSVG /></span>
                                <span className={styles.mlink__text}>{Boolean(propertyCount) ? "Manage listings" : "List your property"}</span>
                            </a>
                        </Link>
                </li>
                

                <li className={styles.mitem}>
                    <a
                        className={clsx(styles.mlink, `${disableLogout ? styles.disabled : ''}`)}
                        onClick={beforeTriggerLogout}
                    >
                        <span className={styles.mlink__svg}><LogoutSVG /></span>
                        <span className={styles.mlink__text}>Sign out</span>
                    </a>
                </li>

            </ul>
        )
    }

}


const MyAccountSVG = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM10 5C10 6.28866 9.10457 7.33333 8 7.33333C6.89543 7.33333 6 6.28866 6 5C6 3.71134 6.89543 2.66667 8 2.66667C9.10457 2.66667 10 3.71134 10 5ZM4.64727 9.95617C4.23313 10.2118 4 10.6696 4 11.1563C4 11.9905 4.67622 12.6667 5.51037 12.6667H10.4896C11.3238 12.6667 12 11.9905 12 11.1563C12 10.6696 11.7669 10.2118 11.3527 9.95617C10.5304 9.4485 9.08739 8.66667 8 8.66667C6.9126 8.66667 5.46963 9.4485 4.64727 9.95617Z" fill="#64748B" />
        </svg>
    );
};


const BookingRequest = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className={styles.staticSvg}>
            <path d="M7.28846 17.885H9.24819C8.33993 16.787 7.84398 15.4061 7.84615 13.9811C7.84615 11.9065 8.87565 10.0717 10.4528 8.96191H4.5V15.0965C4.5 15.8361 4.79378 16.5453 5.31672 17.0683C5.83966 17.5912 6.54892 17.885 7.28846 17.885Z" fill="#64748B"/>
<path d="M17.8846 7.84615H4.5V7.28846C4.5 6.54892 4.79378 5.83966 5.31672 5.31672C5.83966 4.79378 6.54892 4.5 7.28846 4.5H15.0962C15.8357 4.5 16.545 4.79378 17.0679 5.31672C17.5908 5.83966 17.8846 6.54892 17.8846 7.28846V7.84615Z" fill="#64748B"/>
<circle cx="14" cy="14" r="5" fill="#64748B"/>
<path d="M14.04 11.352C14.568 11.352 14.9947 11.5013 15.32 11.8C15.6453 12.0987 15.808 12.4987 15.808 13C15.808 13.56 15.6347 13.976 15.288 14.248C14.9413 14.5147 14.472 14.648 13.88 14.648L13.856 15.408H13.224L13.192 14.144H13.424C13.952 14.144 14.3653 14.064 14.664 13.904C14.9627 13.744 15.112 13.4427 15.112 13C15.112 12.68 15.016 12.4267 14.824 12.24C14.632 12.0533 14.3733 11.96 14.048 11.96C13.7173 11.96 13.456 12.0507 13.264 12.232C13.0773 12.408 12.984 12.6507 12.984 12.96H12.296C12.296 12.64 12.368 12.36 12.512 12.12C12.656 11.8747 12.8587 11.6853 13.12 11.552C13.3867 11.4187 13.6933 11.352 14.04 11.352ZM13.536 17.048C13.3973 17.048 13.28 17 13.184 16.904C13.088 16.808 13.04 16.6907 13.04 16.552C13.04 16.4133 13.088 16.296 13.184 16.2C13.28 16.104 13.3973 16.056 13.536 16.056C13.6693 16.056 13.7813 16.104 13.872 16.2C13.968 16.296 14.016 16.4133 14.016 16.552C14.016 16.6907 13.968 16.808 13.872 16.904C13.7813 17 13.6693 17.048 13.536 17.048Z" fill="#fff"/>
        </svg>
    );
};


const Earnings = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 17" fill="none" className={styles.staticSvg}>
            <circle cx="8.5" cy="8.5" r="8" fill="#64748B"/>
<path d="M11.3782 10.088C11.3782 10.504 11.2702 10.896 11.0542 11.264C10.8462 11.624 10.5342 11.924 10.1182 12.164C9.71022 12.396 9.22622 12.532 8.66622 12.572V13.544H7.89822V12.56C7.09822 12.488 6.45422 12.248 5.96622 11.84C5.47822 11.424 5.22622 10.864 5.21022 10.16H7.01022C7.05822 10.736 7.35422 11.084 7.89822 11.204V8.912C7.32222 8.768 6.85822 8.624 6.50622 8.48C6.15422 8.336 5.85022 8.104 5.59422 7.784C5.33822 7.464 5.21022 7.028 5.21022 6.476C5.21022 5.78 5.45822 5.212 5.95422 4.772C6.45822 4.332 7.10622 4.08 7.89822 4.016V3.044H8.66622V4.016C9.43422 4.08 10.0462 4.312 10.5022 4.712C10.9662 5.112 11.2262 5.664 11.2822 6.368H9.47022C9.44622 6.136 9.36222 5.936 9.21822 5.768C9.08222 5.592 8.89822 5.468 8.66622 5.396V7.664C9.26622 7.816 9.73822 7.964 10.0822 8.108C10.4342 8.244 10.7382 8.472 10.9942 8.792C11.2502 9.104 11.3782 9.536 11.3782 10.088ZM6.96222 6.392C6.96222 6.656 7.04222 6.872 7.20222 7.04C7.36222 7.2 7.59422 7.332 7.89822 7.436V5.36C7.61022 5.4 7.38222 5.508 7.21422 5.684C7.04622 5.86 6.96222 6.096 6.96222 6.392ZM8.66622 11.228C8.97022 11.172 9.20622 11.048 9.37422 10.856C9.55022 10.664 9.63822 10.432 9.63822 10.16C9.63822 9.896 9.55422 9.684 9.38622 9.524C9.21822 9.364 8.97822 9.232 8.66622 9.128V11.228Z" fill="white"/>
        </svg>
    );
};


const MyBookingsSVG = () => {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
            <path d="M5.00129 1H9.57945C9.69271 1 9.80134 1.04499 9.88143 1.12509C9.96152 1.20518 10.0065 1.3138 10.0065 1.42707V2.85774H4.57422V1.42707C4.57422 1.3138 4.61921 1.20518 4.6993 1.12509C4.7794 1.04499 4.88802 1 5.00129 1Z" stroke="#64748B" strokeWidth="1.20433" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0.427067 2.85742H14.153C14.3889 2.85742 14.5801 3.04863 14.5801 3.28449V13.1754C14.5801 13.4112 14.3889 13.6024 14.153 13.6024H0.427067C0.191205 13.6024 0 13.4112 0 13.1754V3.28449C0 3.04863 0.191205 2.85742 0.427067 2.85742ZM2.2124 10.9857C1.87984 10.9857 1.61024 11.2553 1.61024 11.5879C1.61024 11.9205 1.87984 12.1901 2.2124 12.1901H12.3681C12.7006 12.1901 12.9702 11.9205 12.9702 11.5879C12.9702 11.2553 12.7006 10.9857 12.3681 10.9857H2.2124Z" fill="#64748B" />
        </svg>
    );
};

const ListsSVG = () => {
    return (
        <svg  width="20" height="16" viewBox="0 0 20 18" fill="#64748B" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.48866 2.31323L10 2.86016L10.5113 2.31323C12.5223 0.162257 15.762 0.162257 17.7729 2.31323C19.809 4.49107 19.809 8.0403 17.7729 10.2181L11.9739 16.4208C10.878 17.5931 9.12197 17.5931 8.02606 16.4208L2.22706 10.2181C0.190978 8.0403 0.190978 4.49107 2.22706 2.31323C4.23804 0.162257 7.47769 0.162257 9.48866 2.31323Z"  strokeWidth="1.4"/>
        </svg>
    );
};

const InboxSVG = () => {
    return (
        <svg width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path d="M13.3749 9.91248H2.9877C2.19531 9.91248 1.43538 9.58815 0.875076 9.01084C0.314774 8.43352 0 7.65052 0 6.83407V3.07841C0 2.26197 0.314774 1.47896 0.875076 0.901646C1.43538 0.324332 2.19531 0 2.9877 0H14.0123C14.8047 0 15.5646 0.324332 16.1249 0.901646C16.6852 1.47896 17 2.26197 17 3.07841V11.503C16.9972 11.5953 16.9703 11.6851 16.9221 11.7629C16.8739 11.8408 16.8062 11.9037 16.7261 11.9452C16.6461 11.9868 16.5566 12.0052 16.4672 11.9987C16.3778 11.9922 16.2918 11.961 16.2182 11.9083L13.3749 9.91248Z" fill="#64748B" />
        </svg>
    );
};

const LypSVG = () => {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path d="M15.7314 6.73293L13.3066 4.30805V1.50691C13.3066 1.00042 12.8961 0.589954 12.3889 0.589954C11.8828 0.589954 11.4724 1.00042 11.4724 1.50691V2.47392L9.66688 0.66834C8.77421 -0.22384 7.22217 -0.222257 6.33154 0.669955L0.268439 6.73293C-0.0894796 7.09156 -0.0894796 7.67184 0.268439 8.02992C0.626518 8.38845 1.20793 8.38845 1.56588 8.02992L7.6284 1.96679C7.8259 1.77032 8.174 1.77032 8.37047 1.96621L14.434 8.02992C14.6138 8.2092 14.8482 8.29838 15.0826 8.29838C15.3174 8.29838 15.5522 8.2091 15.7315 8.02992C16.0895 7.67187 16.0895 7.09159 15.7314 6.73293Z" fill="#64748B" />
            <path d="M8.31885 3.70723C8.1427 3.53118 7.85748 3.53118 7.68185 3.70723L2.34873 9.03877C2.26453 9.12294 2.2168 9.23785 2.2168 9.35777V13.2464C2.2168 14.1589 2.95666 14.8987 3.86913 14.8987H6.50956V10.8096H9.49056V14.8987H12.131C13.0435 14.8987 13.7833 14.1589 13.7833 13.2464V9.35777C13.7833 9.23785 13.736 9.12294 13.6514 9.03877L8.31885 3.70723Z" fill="#64748B" />
        </svg>
    );
};

const LogoutSVG = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 13V11H17L20 11.5V12.5L17 13H15Z" fill="#7B61FF" />
                <path d="M21.4737 12.5023C21.7664 12.1949 21.7605 11.71 21.4603 11.4097L17.7574 7.70688C17.3669 7.31636 16.7337 7.31636 16.3432 7.70688C15.9527 8.09741 15.9527 8.73057 16.3432 9.1211L18.0563 10.8342C18.0927 10.8706 18.1319 10.9041 18.1734 10.9345L20.4457 12.5945C20.7629 12.8263 21.2027 12.7868 21.4737 12.5023Z" fill="#7B61FF" />
                <path d="M21.4485 11.4973C21.7413 11.8048 21.7354 12.2897 21.4351 12.5899L17.7323 16.2928C17.3417 16.6833 16.7086 16.6833 16.3181 16.2928C15.9275 15.9022 15.9275 15.2691 16.3181 14.8785L18.0311 13.1655C18.0675 13.1291 18.1067 13.0955 18.1483 13.0651L20.4205 11.4051C20.7377 11.1733 21.1776 11.2128 21.4485 11.4973Z" fill="#7B61FF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M13 4H7C5.89543 4 5 4.89543 5 6V18C5 19.1046 5.89543 20 7 20H13C14.1046 20 15 19.1046 15 18V13H11C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11H15V6C15 4.89543 14.1046 4 13 4Z" fill="#7B61FF" />
            </svg>
        </>
    );
};