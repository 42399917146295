import { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getCurrenciesActions, selectCurrency } from '@/redux/actions/globalActions';
import { checkCurrencyLocalStorageValidity } from '@/src/utils/globalValidations';
import { initWishList } from '@/redux/actions/wishListActions';
import { logoutAction } from '@/redux/actions/authActions';
import { useRouter } from "next/router";


const useHeader = ({ isMobile }) => {
    const dispatch = useDispatch();
    const { data: authState } = useSelector(state => state.authReducer, shallowEqual);
    const lists = useSelector(state => state.wishList.lists);
    const currencies = useSelector(state => state.currencies);
    const handleLogout = () => dispatch(logoutAction());
    const router = useRouter();
    const pathLast = router.pathname.split('/').pop();
    const pathFirst = router.pathname.split('/')[1];
    const determineColor = () => {
        const mob = !['', 'about-us', 'affiliate', 'eid-al-adha'].includes(pathLast) &&
            !['a', 't', 'l', 'hotel', 'listings'].includes(pathFirst);
        const desk = !['', 'about-us', 'affiliate', 'eid-al-adha'].includes(pathLast) &&
            !['a', 't', 'l'].includes(pathFirst);
        return isMobile ? mob : desk;
    };
    const [logoColored, setLogoColored] = useState(determineColor);
    const [positionAbs, setPositionAbs] = useState(() => {
        const mob = ['', 'about-us', 'affiliate', 'eid-al-adha'].includes(pathLast) ||
            ['a', 't', 'l', 'hotel', 'listings', 'reservation'].includes(pathFirst);
        const desk = ['', 'about-us', 'affiliate', 'eid-al-adha', 'list_space'].includes(pathLast) ||
            ['a', 't', 'l', 'reservation'].includes(pathFirst);
        return isMobile ? mob : desk;
    });
    const [positionFixed, setPositionFixed] = useState(() => {
        const mob = [
            'wishlist',
            'newsroom',
            'terms-and-conditions',
            'privacy-policy',
            'cancellation_policies',
            'contact-us',
            'list-your-property',
            'care-program',
            'help',
            'promise',
            'review'].includes(pathLast) ||
            ['booking-payment'].includes(pathFirst);
        const desk = [
            'wishlist',
            'newsroom',
            'terms-and-conditions',
            'privacy-policy',
            'cancellation_policies',
            'contact-us',
            'list-your-property',
            'care-program',
            'help',
            'promise',
            'review'].includes(pathLast) ||
            ['booking-payment'].includes(pathFirst);
        return isMobile ? mob : desk;
    });
    const [blog, setBlog] = useState(() => {
        return (
            !(['', 'sr', 'blog', 'list_space', 'affiliate'].includes(pathLast))
            && !(['hotel', 'booking-payment', 'crypto'].includes(pathFirst))
        );
    });

    useEffect(() => {
        if (authState && !lists.length) {
            dispatch(initWishList());
        }
    }, [authState]);

    useEffect(() => {
        const localStorageCurrency = checkCurrencyLocalStorageValidity();
        if (localStorageCurrency) {
            dispatch(selectCurrency(localStorageCurrency));
        }
        dispatch(getCurrenciesActions());
    }, []);

    return [
        authState,
        lists,
        currencies,
        handleLogout,
        pathLast,
        pathFirst,
        logoColored,
        setLogoColored,
        determineColor,
        positionAbs,
        positionFixed,
        blog
    ];
};

export default useHeader;
